<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <MyTable
            :columns="columns"
            :rows="rows"
            :isLoading="isLoading"
            :hasExport="true"
            @handleExport="handleExport"
          >
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button
                  variant="gradient-primary"
                  block
                  @click="goToLogPointDetails(props.row.id)"
                >
                  {{ props.row.id }}
                </b-button>
              </span>
              <div v-if="props.column.field === 'name'">
                {{ !props.row.name ? "" : props.row.name }}
              </div>
              <div v-if="props.column.field === 'email'">
                {{ !props.row.email ? "" : props.row.email }}
              </div>
              <div v-if="props.column.field === 'contact'">
                {{ !props.row.contact ? "" : props.row.contact }}
              </div>
              <!-- <div v-if="props.column.field === 'user_type'">
                  {{ props.row.user_type === 2 ? "Outlet User" : (props.row.user_type === 3)? "B2C User" : "" }}
                </div> -->
              <span v-if="props.column.field === 'user_type'">
                <b-button variant="gradient-primary" block>
                  {{
                    props.row.user_type === 2
                      ? "Outlet User"
                      : props.row.user_type === 3
                      ? "B2C User"
                      : ""
                  }}
                </b-button>
              </span>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
    <!-- <ValidationObserver ref="logpoint">
        <b-modal
          size="lg"
          ref="handleLP"
          centered
          title="ADD / REDUCE LP"
          hide-footer
          no-close-on-backdrop
        >
          <b-form>
            <b-row>
              <b-col>
                <b-form-group label="Username" class="w-100">
                  <b-row>
                    <b-col cols="6" class="pr-0 d-flex align-items-center">
                      <div class="d-flex">
                        <b-form-radio
                          v-model="userType"
                          name="User"
                          value="User"
                          class="mr-2"
                        >
                          User
                        </b-form-radio>
                        <b-form-radio
                          v-model="userType"
                          name="Outlet"
                          value="Outlet"
                        >
                          Outlet
                        </b-form-radio>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <div>
                        <ValidationProvider
                          v-slot="{ errors }"
                          vid="Username"
                          name="Username"
                          rules="required"
                        >
                          <v-select
                            v-model="userName"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="userOptions"
                            :clearable="false"
                            label="name"
                            required
                            placeholder="Username"
                            class="font-small-3 w-100"
                          >
                            <template #option="option">
                              <span>{{ option.name }}</span>
                            </template>
                          </v-select>
                          <span>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </span>
                        </ValidationProvider>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Amount LP" class="w-100">
                  <b-row>
                    <b-col cols="6" class="pr-0 d-flex align-items-center">
                      <div class="d-flex">
                        <b-form-radio
                          v-model="mode"
                          name="Add"
                          value="Add"
                          class="mr-2"
                        >
                          Add
                        </b-form-radio>
                        <b-form-radio v-model="mode" name="Reduce" value="Reduce">
                          Reduce
                        </b-form-radio>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <div>
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="LP Amount"
                          rules="required"
                        >
                          <input
                            v-model="amount"
                            type="text"
                            class="form-control font-small-3"
                            placeholder="LP Amount"
                            aria-label="LP Amount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Type of Shipment" class="w-100">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Shipment Type"
                    rules="required"
                  >
                    <v-select
                      v-model="shipmentType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="shipmentOptions"
                      :clearable="false"
                      label="shipmentType"
                      required
                      placeholder="Shipment Type"
                      class="font-small-3 w-100"
                    >
                      <template #option="option">
                        <span>{{ option.shipmentType }}</span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Remarks" class="w-100">
                  <ValidationProvider
                    #default="{ errors }"
                    name="Remarks"
                    rules="required"
                    class="w-100"
                  >
                    <input
                      v-model="remarks"
                      type="text"
                      class="form-control font-small-3"
                      placeholder="Remarks"
                      aria-label="Remarks"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-2 d-flex justify-content-center">
              <b-button
                @click.prevent="addOrReduceLP()"
                variant="gradient-primary"
                type="submit"
              >
                SUBMIT
              </b-button>
            </b-row>
          </b-form>
        </b-modal>
      </ValidationObserver> -->
  </b-container>
</template>
<script>
import MyTable from "@/views/components/MyTable.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import exportFromJSON from "export-from-json";
import { required } from "@validations";

export default {
  components: {
    MyTable,
    ValidationProvider,
    ValidationObserver,
  },

  created() {
    this.$http.get(`getUserB2cAndOutlet`).then((response) => {
      if (response.data.status) {
        this.rows = response.data.data;
      } else {
        this.$swal({
          title: "Error !",
          icon: "warning",
          text: response.data.message,
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
      }
      this.isLoading = false;
    });
  },

  methods: {
    handleExport(type) {
      const fileName = "generated-users";
      const data = this.rows.map((item) => ({
        "User ID": item.id,
        Name: item.name,
        Email: item.email,
        "User Type":
          item.user_type === 2
            ? "Outlet User"
            : item.user_type === 3
            ? "B2C User"
            : "",
      }));

      let exportType;
      switch (type) {
        case "xlsx":
          exportType = exportFromJSON.types.xls;
          exportFromJSON({ data, fileName, exportType });
          break;
        case "csv":
          exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
          break;
      }
    },

    goToLogPointDetails(id) {
      this.$router.push({
        name: "logpoint-details",
        params: {
          id: id,
        },
      });
    },
  },

  data() {
    return {
      isLoading: true,
      columns: [
        {
          label: "User ID",
          field: "id",
          tdClass: "text-center align-middle",
        },
        {
          label: "Name",
          field: "name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Email",
          field: "email",
          tdClass: "text-center align-middle",
        },
        {
          label: "User Type",
          field: "user_type",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
      required,
    };
  },
};
</script>
